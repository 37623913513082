import { availabilityService } from "../services/availableProductsService";
import { addOnsService } from "../services/addOnsService";
import {
  pnrBeforeNIService,
  pnrAfterNIService,
  pnrDeleteService,
} from "../services/pnrService";
import {
  cardPaymentService,
  paymentService,
  paymentStatusService,
  samsungPayService,
} from "../services/paymentService";

import { couponCodeService } from "../services/couponCodeService.js";
import { accountService } from "../services/accountService";
import { addtoCartService, editCartService } from "../services/cartService";
import { pdfService } from "../services/pdfService";
import { bookingnotificationService } from "../services/bookingnotificationService";
import { couponAppliedToGTM, addtocartExtras, removefromcartExtras } from "../providers/dataLayerAnalytics.js";

export const UPDATE_BOOKINGDETAILS_BYKEY = "UPDATEBOOKINGDETAILSBYKEY";
export const RESET_BOOKINGDETAILS_BYKEY = "RESET_BOOKINGDETAILS_BYKEY";

export function updateBookingDetailsbyKey(payload) {
  //console.log(payload);
  return {
    type: UPDATE_BOOKINGDETAILS_BYKEY,
    payload,
  };
}
export function resetBookingDetailsbyKey(payload) {
  //console.log(payload);
  return {
    type: RESET_BOOKINGDETAILS_BYKEY,
    payload,
  };
}

export function fetchAvailability() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();
    let requestPayload = {
      dateofVisit: bookingDetailsV2.dateofVisit,
      guestInfo: bookingDetailsV2.guestInfo,
    };
    return new Promise(async (resolve, reject) => {
      return await availabilityService(requestPayload)
        .then((availabilityResponse) => {
          dispatch(
            updateBookingDetailsbyKey({ availability: availabilityResponse })
          );
          resolve({ status: 200, message: availabilityResponse });
        })
        .catch((err) => {
          console.log(err);
          reject({ status: 500, message: err });
        });
    });
  };
}

export function fetchAddons() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let formproductId = [];

    //console.log(
    //  formproductId,
    //  "formproductIdformproductIdformproductIdformproductId"
    //);

    if (bookingDetailsV2.isPackage.selected) {
      Object.entries(bookingDetailsV2.isPackage.additionalGuestInfo).forEach(
        ([key, value]) => {
          if (value > 0) {
            formproductId.push(
              bookingDetailsV2.selectedTourType.productId[key]
            );
          }
        }
      );
      Object.entries(bookingDetailsV2.guestInfo).forEach(([key, value]) => {
        if (value > 0) {
          formproductId.push(bookingDetailsV2.selectedTourType.productId[key]);
        }
      });
    } else {
      Object.entries(bookingDetailsV2.guestInfo).forEach(([key, value]) => {
        if (value > 0) {
          formproductId.push(bookingDetailsV2.selectedTourType.productId[key]);
        }
      });
    }
    //console.log(
    //  formproductId,
    //  "formproductIdformproductIdformproductIdformproductId"
    //);

    let requestPayload = {
      timeofVisit: bookingDetailsV2.timeofVisit,
      dateofVisit: bookingDetailsV2.dateofVisit,
      guestInfo: bookingDetailsV2.guestInfo,
      additionalGuestInfo: bookingDetailsV2.isPackage.additionalGuestInfo,
      isPackage: bookingDetailsV2.isPackage,
      performanceId: bookingDetailsV2.performanceId,
      productId: formproductId,
      isJumeirahGuest: bookingDetailsV2.isJumeirahGuest,
    };

    return new Promise(async (resolve, reject) => {
      return await addOnsService(requestPayload)
        .then((addOnResponse) => {
          dispatch(updateBookingDetailsbyKey({ cartBase: addOnResponse }));
          resolve({ status: 200, message: addOnResponse });
        })
        .catch((error) => {
          console.log(error);
          reject({ status: 500, message: error });
        });
    });
  };
}

export function addToCart() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      cartBase: bookingDetailsV2.cartBase,
      performanceId: bookingDetailsV2.performanceId,
      addOnsData: bookingDetailsV2.addOnsData,
      addOns: bookingDetailsV2.addOns,
      };

    //addtocartExtras(bookingDetailsV2.guestInfo, bookingDetailsV2.cartBase, bookingDetailsV2.selectedTourType, bookingDetailsV2.dateofVisit, bookingDetailsV2.timeofVisit);

    return new Promise(async (resolve, reject) => {
      return await addtoCartService(requestPayload)
        .then((addtoCartResponse) => {
          dispatch(
            updateBookingDetailsbyKey({
              cartBase: { ...requestPayload.cartBase, ...addtoCartResponse },
              addOnServiceErr:false,
            })
          );
          resolve({ status: 200, message: addtoCartResponse });
        })
        .catch((err) => {
          dispatch(
            updateBookingDetailsbyKey({
                toastmessage: {
                  showToast: true,
                  header: "Common",
                  description: "NotAvailableProduct",
              },
              addOnServiceErr:true,
            })
          );
          reject({ status: 500, message: err });
        });
    });
    };
}

export function editCart() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      cartBase: bookingDetailsV2.cartBase,
      performanceId: bookingDetailsV2.performanceId,
      addOnsData: bookingDetailsV2.addOnsData,
      addOns: bookingDetailsV2.addOns,
      selectedTourType: bookingDetailsV2.selectedTourType,
      };

      //addtocartExtras(bookingDetailsV2.guestInfo, bookingDetailsV2.cartBase, bookingDetailsV2.selectedTourType, bookingDetailsV2.dateofVisit, bookingDetailsV2.timeofVisit);

    return new Promise(async (resolve, reject) => {
      return await editCartService(requestPayload)
        .then((editCartResponse) => {
          dispatch(
            updateBookingDetailsbyKey({
              cartBase: { ...requestPayload.cartBase, ...editCartResponse },
            })
          );
          resolve({ status: 200, message: editCartResponse });
        })
        .catch((err) => {
          console.log(err);
          reject({ status: 500, message: err });
        });
    });
    };
}
export const applyDiscount = () => {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();
    let requestPayload = {
      shopCartId: bookingDetailsV2.cartBase.shopCartId,
      couponCode: bookingDetailsV2.couponCode.value,
    };
    return new Promise(async (resolve, reject) => {
      return await couponCodeService(requestPayload)
        .then((couponResponse) => {
          dispatch(
            updateBookingDetailsbyKey({
              cartBase: { ...bookingDetailsV2.cartBase, ...couponResponse },
            })
          );

          const { bookingDetailsV2: updatedBookingDetailsV2 } = getState();

          couponAppliedToGTM({
            ccode: updatedBookingDetailsV2.couponCode.value,
            discountApplied: updatedBookingDetailsV2.cartBase.totalDiscount,
            tourTypeName: updatedBookingDetailsV2.selectedTourType.name,
          });

          //reset couponcode in redux after trying once,user needs to type the code again to retry.
          dispatch(
            updateBookingDetailsbyKey({
              couponCode: "",
            })
          );
          resolve({ status: 200, message: "Coupon Applied" });
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            updateBookingDetailsbyKey({
              couponCode: "",
            })
          );
          reject({ status: 500, message: err });
        });
    });
  };
};

//since all calls happen after confirm button is clicked we are grouping them as beforepaymentservice and afterpaymentservice
//execute  services one after the other

//accountService->pnrNumber->nipayments->paymentstatus->pnrdeletion(if payment failed)->pnrAfterNiservice and redirect confirmation page (if payment success)

export function confirmationBeforePayment() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      cartBase: bookingDetailsV2.cartBase,
      mainGuestDetails: bookingDetailsV2.mainGuestDetails,
      giftGuestDetails: bookingDetailsV2.giftGuestDetails,
      outletReference: bookingDetailsV2.outletReference,
      paymentMethod: bookingDetailsV2.paymentMethod,
      sessionId: bookingDetailsV2.sessionId,
    };
          //let accountServiceResponse = await accountService(requestPayload)
          //    .then((response) => {
          //        console.log("Acccount details", response);
          //        if (response.mainId) {
          //            return true;
          //        } else {
          //            //account not created
          //            return false;
          //        }
          //    })
          //    .catch((response) => {
          //        //account not created
          //        console.log("Cannot create Account for booking", response);
          //        return false;
          //    });

         let pnrBeforeNIResponse =    {
                        saleCode: "",
                        paymentStatus: false,
                        saleId: "",
                        transactionId: "",
                        pahDownloadUrl: "",
         }
          //if (accountServiceResponse) {
          //    if (
          //        bookingDetailsV2.cartBase.totalDiscount &&
          //        Number(bookingDetailsV2.cartBase.totalDiscount) > 0
          //    ) {
          //        if (
          //            bookingDetailsV2.paymentMethod == "Apple_Pay" ||
          //            bookingDetailsV2.paymentMethod == "Samsung_Pay"
          //        ) {
          //            await paymentService(requestPayload)
          //                .then(async (paymentServiceResponse) => {
          //                    //reset pnrInfo in redux before payment
          //                    dispatch(
          //                        updateBookingDetailsbyKey({
          //                            pnrInfo: {
          //                                saleCode: "",
          //                                paymentStatus: false,
          //                                saleId: "",
          //                                transactionId: "",
          //                            },
          //                        })
          //                    );

          //                    dispatch(
          //                        updateBookingDetailsbyKey({
          //                            paymentInfo: paymentServiceResponse,
          //                        })
          //                    );
          //                    setTimeout(() => { }, 1000);

          //                    if (bookingDetailsV2.paymentMethod == "Apple_Pay") {
          //                        console.log(
          //                            "APPLE_PAY_URL",
          //                            paymentServiceResponse.applePaypaymentURL
          //                        );
          //                        console.log(
          //                            "wallet_token",
          //                            paymentServiceResponse.wallet_token
          //                        );
          //                        const applePayObject = {
          //                            APPLE_PAY_URL: paymentServiceResponse.applePaypaymentURL,
          //                            wallet_token: paymentServiceResponse.wallet_token,
          //                            payment_amount: paymentServiceResponse.payment_amount,
          //                        };
          //                        sessionStorage.setItem(
          //                            "ApplePay_payment",
          //                            JSON.stringify(applePayObject)
          //                        );
          //                        return true;
          //                    } else if (bookingDetailsV2.paymentMethod == "Samsung_Pay") {
          //                        const samsungPayObject = {
          //                            // "SAMSUNG_PAY_URL":paymentServiceResponse.samsungPaypaymentURL,
          //                            outletRef: paymentServiceResponse.outletId,
          //                            orderRef: paymentServiceResponse.ref,
          //                            paymentRef: paymentServiceResponse.paymentRef,
          //                            data: {
          //                                cancelUrl: window.location.href,
          //                                successUrl:
          //                                    window.location.origin.replace("localhost", "127.0.0.1") +
          //                                    `/${window.location.pathname.substring(
          //                                        1,
          //                                        3
          //                                    )}/tour-booking/booking-confirmation`,
          //                            },
          //                        };
          //                        localStorage.setItem(
          //                            "samsungPayObject",
          //                            JSON.stringify(samsungPayObject)
          //                        );
          //                    }
          //                })
          //                .catch((data) => {
          //                    dispatch(
          //                        updateBookingDetailsbyKey({
          //                            pnrInfo: {
          //                                ...bookingDetailsV2.pnrInfo,
          //                                paymentStatus: false,
          //                            },
          //                            paymentInfo: data,
          //                        })
          //                    );
          //                    window.open(
          //                        window.location.origin +
          //                        `/${window.location.pathname.substring(
          //                            1,
          //                            3
          //                        )}/tour-booking/booking-confirmation`,
          //                        "_self"
          //                    );
          //                    console.log("error in payment service NI");
          //                });
          //        } else {
          //            await cardPaymentService(requestPayload)
          //                .then((paymentServiceResponse) => {
          //                    //reset pnrInfo in redux before payment
          //                    dispatch(
          //                        updateBookingDetailsbyKey({
          //                            pnrInfo: {
          //                                saleCode: "",
          //                                paymentStatus: false,
          //                                saleId: "",
          //                                transactionId: "",
          //                            },
          //                        })
          //                    );

          //                    dispatch(
          //                        updateBookingDetailsbyKey({
          //                            paymentInfo: paymentServiceResponse,
          //                        })
          //                    );
          //                    setTimeout(() => { }, 1000);
          //                    return true;
          //                })
          //                .catch((data) => {
          //                    dispatch(
          //                        updateBookingDetailsbyKey({
          //                            pnrInfo: {
          //                                ...bookingDetailsV2.pnrInfo,
          //                                paymentStatus: false,
          //                            },
          //                            paymentInfo: data,
          //                        })
          //                    );
          //                    window.open(
          //                        window.location.origin +
          //                        `/${window.location.pathname.substring(
          //                            1,
          //                            3
          //                        )}/tour-booking/booking-confirmation`,
          //                        "_self"
          //                    );
          //                    console.log("error in payment service NI");
          //                });
          //        }
          //    } else {
                  //await pnrBeforeNIService(requestPayload)
                  //    .then(async (pnrBeforeNIResponse) => {

                          dispatch(
                              updateBookingDetailsbyKey({ pnrInfo: "" })
                          );

                          if (
                              bookingDetailsV2.paymentMethod == "Apple_Pay" ||
                              bookingDetailsV2.paymentMethod == "Samsung_Pay"
                          ) {
                              await paymentService(requestPayload)
                                  .then(async (paymentServiceResponse) => {
                                      dispatch(
                                          updateBookingDetailsbyKey({
                                              paymentInfo: paymentServiceResponse,
                                          })
                                      );
                                      setTimeout(() => { }, 5000);

                                      if (bookingDetailsV2.paymentMethod == "Apple_Pay") {
                                          console.log(
                                              "APPLE_PAY_URL",
                                              paymentServiceResponse.applePaypaymentURL
                                          );
                                          console.log(
                                              "wallet_token",
                                              paymentServiceResponse.wallet_token
                                          );
                                          const applePayObject = {
                                              APPLE_PAY_URL: paymentServiceResponse.applePaypaymentURL,
                                              wallet_token: paymentServiceResponse.wallet_token,
                                              payment_amount: paymentServiceResponse.payment_amount,
                                          };
                                          sessionStorage.setItem(
                                              "ApplePay_payment",
                                              JSON.stringify(applePayObject)
                                          );
                                          return true;
                                      } else if (bookingDetailsV2.paymentMethod == "Samsung_Pay") {
                                          const samsungPayObject = {
                                              // "SAMSUNG_PAY_URL":paymentServiceResponse.samsungPaypaymentURL,
                                              outletRef: paymentServiceResponse.outletId,
                                              orderRef: paymentServiceResponse.ref,
                                              paymentRef: paymentServiceResponse.paymentRef,
                                              data: {
                                                  cancelUrl: window.location.href,
                                                  successUrl:
                                                      window.location.origin.replace(
                                                          "localhost",
                                                          "127.0.0.1"
                                                      ) +
                                                      `/${window.location.pathname.substring(
                                                          1,
                                                          3
                                                      )}/tour-booking/booking-confirmation`,
                                              },
                                          };
                                          localStorage.setItem(
                                              "samsungPayObject",
                                              JSON.stringify(samsungPayObject)
                                          );
                                      }
                                  })
                                  .catch((data) => {
                                      dispatch(
                                          updateBookingDetailsbyKey({
                                              pnrInfo: { ...pnrBeforeNIResponse, paymentStatus: false },
                                              paymentInfo: data,
                                          })
                                      );
                                      window.open(
                                          window.location.origin +
                                          `/${window.location.pathname.substring(
                                              1,
                                              3
                                          )}/tour-booking/booking-confirmation`,
                                          "_self"
                                      );
                                      console.log("error in payment service NI");
                                  });
                          } else {
                              await cardPaymentService(requestPayload)
                                  .then((paymentServiceResponse) => {
                                      console.log("paymentServiceResponse", paymentServiceResponse);
                                      dispatch(
                                          updateBookingDetailsbyKey({
                                              paymentInfo: paymentServiceResponse,
                                          })
                                      );

                                      console.log("cardpayment service is success - 1");
                                      setTimeout(() => { }, 5000);
                                      console.log("cardpayment service is success - 2");
                                      return true;
                                  })
                                  .catch((data) => {
                                      console.log("error in payment service NI",data);

                                      dispatch(
                                          updateBookingDetailsbyKey({
                                              pnrInfo: { ...pnrBeforeNIResponse, paymentStatus: false },
                                              paymentInfo: data,
                                          })
                                      );
                                      window.open(
                                          window.location.origin +
                                          `/${window.location.pathname.substring(
                                              1,
                                              3
                                          )}/tour-booking/booking-confirmation`,
                                          "_self"
                                      );
                                  });
                          }

                      //})
                      //.catch((err) => {
                      //    console.log(err);
                      //    dispatch(
                      //        updateBookingDetailsbyKey({
                      //            pnrInfo: { ...bookingDetailsV2.pnrInfo, paymentStatus: false },
                      //        })
                      //    );
                      //    window.open(
                      //        window.location.origin +
                      //        `/${window.location.pathname.substring(
                      //            1,
                      //            3
                      //        )}/tour-booking/booking-confirmation`,
                      //        "_self"
                      //    );
                      //});
              //}
          //} else {
          //    dispatch(
          //        updateBookingDetailsbyKey({
          //            toastmessage: {
          //                showToast: true,
          //                header: "Account Creation Error",
          //                description: "Please check entered guest details and try again.",
          //            },
          //        })
          //    );
          //    console.log("Account creation error");
          //}
  };
}

export function confirmationAfterPayment() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      pnrInfo: bookingDetailsV2.pnrInfo,
      cartBase: bookingDetailsV2.cartBase,
      paymentInfo: bookingDetailsV2.paymentInfo,
      mainGuestDetails: bookingDetailsV2.mainGuestDetails,
      giftGuestDetails: bookingDetailsV2.giftGuestDetails,
      isJumeirahGuest: bookingDetailsV2.isJumeirahGuest,
      outletReference: bookingDetailsV2.outletReference,
      };

      let accountServiceResponse = await accountService(requestPayload)
          .then((response) => {
              console.log("Acccount details", response);
              if (response.mainId) {
                  return true;
              } else {
                  //account not created
                  return false;
              }
          })
          .catch((response) => {
              //account not created
              console.log("Cannot create Account for booking", response);
              return false;
          });

    await paymentStatusService(requestPayload)
      .then((paymentStatusServiceResponse) => {
        console.log(
          paymentStatusServiceResponse,
          "paymentStatusServiceResponsepaymentStatusServiceResponsepaymentStatusServiceResponse"
        );
        if (paymentStatusServiceResponse) {
          let ainDubai = checkIfAinDubaiExistsInCart(
            requestPayload.cartBase.items
          );
          requestPayload.ainDubai = ainDubai;

          dispatch(
            updateBookingDetailsbyKey({
              isAinDubai: ainDubai,
            })
          );
          return new Promise(async (resolve, reject) => {
            return await pnrAfterNIService(
              paymentStatusServiceResponse,
              requestPayload
            )
              .then((pnrAfterNIServiceResponse) => {
                dispatch(
                  updateBookingDetailsbyKey({
                    pnrInfo: {
                      ...bookingDetailsV2.pnrInfo,
                      ...pnrAfterNIServiceResponse,
                      paymentStatus: true,
                    },
                  })
                );
                resolve({ status: 200, message: pnrAfterNIServiceResponse });
              })
              .catch((err) => {
                console.log(err);
                reject({ status: 500, message: err });
              });
          });
        } else {
          throw "error";
        }
      })
      .catch((err) => {
        dispatch(
          updateBookingDetailsbyKey({
            pnrInfo: { ...bookingDetailsV2.pnrInfo, paymentStatus: false },
          })
        );

        console.log(err, "payment Status error");
      });
  };
}
const checkIfAinDubaiExistsInCart = (items) => {
  return items.some((each) => each.ProductName.includes("Ain Dubai"));
};

export function downloadPDF() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      downloadUrl: bookingDetailsV2.pnrInfo.pahDownloadUrl,
    };

    return new Promise(async (resolve, reject) => {
      await pdfService(requestPayload)
        .then((pdfServiceResponse) => {
          resolve(pdfServiceResponse);
        })
        .catch((err) => {
          reject(err);
          console.log(err, "pdf Download error");
        });
    });
  };
}

export function sendbookingunsuccessfulNotification() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      saleId: bookingDetailsV2.pnrInfo.saleId,
      emailAddress: bookingDetailsV2.mainGuestDetails.email.value,
    };

    return new Promise(async (resolve, reject) => {
      await bookingnotificationService(requestPayload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
